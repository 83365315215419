import React, { useState } from 'react';
import imageStyles from '../styles/image.module.css'
import popupStyles from '../styles/popup.module.css'
import 'animate.css/animate.min.css'

export default function ImageLoader(props) {

  const [currentImage, setCurrentImage] = useState('')

  return (
    <div>
      <div id="popup-container" className={[popupStyles.container, popupStyles.hidePopup].join(" ")} role="banner" onClick={hidePopup} onKeyDown={hidePopup}>
        <div className={popupStyles.popup}>
          <div className={popupStyles.closeFlex}>
            <span className={popupStyles.closeButton}>&times;</span>
          </div>
          <img alt="popup" className={popupStyles.image} src={currentImage}/>
        </div>
      </div>
      {props.children}
      <div className={imageStyles.twoImagesContainer}>
        {loadImages(props.data, setCurrentImage)}
      </div>
    </div>
  )
}

function loadImages(data, setCurrentImage) {
  let images = [];
  let dataImages = data.allMarkdownRemark.edges[0].node.frontmatter.images;
  let rowCount = data.allMarkdownRemark.edges[0].node.frontmatter.rowCount;
  let index = 0;
  if (dataImages) {
    for (let image of dataImages) {
      let imageTag = <img src={image.image} alt="Bild" key={index} onClick={((e) => { setCurrentImage(image.image); openPopup() })} />;
      let styledImage = generateImageClasses(imageStyles, dataImages.length, index, rowCount, imageTag);
      images.push(styledImage);
      index++;
    }
  }
  return images;
}

function hidePopup() {
  let container = document.getElementById("popup-container").classList;
  container.add(popupStyles.closePopupContainer);
  setTimeout(() => {
    container.add(popupStyles.hidePopup);
    container.remove(popupStyles.closePopupContainer);
  }, 200)

}

function openPopup() {
  document.getElementById("popup-container").classList.remove(popupStyles.hidePopup);
}

function generateImageClasses(moduleName, imagesLength, imageIndex, rowCount, image) {
  let styledImage;
  let width;
  let imageOffset = imagesLength % rowCount;

  if (imageIndex < imagesLength - imageOffset) {
    width = 100 / rowCount;
  } else {
    width = 100 / imageOffset;
  }
  styledImage = React.cloneElement(image, { style: { width: "calc(" + width + "% - 4px)" }, className: moduleName.galleryImage + " animate__animated animate__fadeInUp animate__slow" })
  return styledImage;
}
